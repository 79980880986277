
export default {
  data: () => ({
    isCalendlyActive: false,
  }),
  computed: {
    isBannerActive() {
      return this.$store.state.banner.isBannerActive;
    },
  },

  mounted() {
    setTimeout(() => {
      this.$refs.banner?.classList.add("animation-reveal");
    }, 1500);
  },

  methods: {
    toggleBanner() {
      this.$store.commit("banner/toggle");
    },

    activeCalendly() {
      this.isCalendlyActive = false;
      this.$nextTick(() => {
        this.isCalendlyActive = true;
        this.$refs.calendly?.activeCalendly();
      });
    },
  },
};
